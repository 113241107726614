import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import View from '@emcasa/ui-dom/components/View';
import Text from '@emcasa/ui-dom/components/Text';
import Button from '@emcasa/ui-dom/components/Button';
import RadioButton from '@emcasa/ui-dom/components/RadioButton';
import Col from '@emcasa/ui-dom/components/Col';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "group"
    }}>{`Group`}</h1>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Fragment mdxType="Fragment">
  <Text mdxType="Text">Uncontrolled radio group</Text>
  <RadioButton.Group initialValue="a" onChange={value => console.log('selected', value)}>
    <RadioButton label="Option 1" value="a" mdxType="RadioButton" />
    <RadioButton label="Option 2" value="b" mdxType="RadioButton" />
    <RadioButton label="Option 3" value="c" disabled mdxType="RadioButton" />
  </RadioButton.Group>
  <Text mdxType="Text">Controlled radio group</Text>
  <RadioButton.Group selectedValue="a" onChange={value => console.log('selected', value)}>
    <RadioButton label="Option 1" value="a" mdxType="RadioButton" />
    <RadioButton label="Option 2" value="b" mdxType="RadioButton" />
    <RadioButton label="Option 3" value="c" disabled mdxType="RadioButton" />
  </RadioButton.Group>
    </Fragment>
    <h2 {...{
      "id": "renderitem"
    }}>{`renderItem`}</h2>
    <p>{`All children on a Group must be an instance of it's base component, or at least work with the same props.
use `}<inlineCode parentName="p">{`renderItem`}</inlineCode>{` to render components around each child.`}</p>
    <Fragment mdxType="Fragment">
  <Text mdxType="Text">With option wrapper</Text>
  <Button.Group initialValue="b" onChange={values => console.log('selected', values)} renderItem={(option, props) => <Col width={1 / 4} m={4} mdxType="Col">{option}</Col>}>
    <Button fluid value="a" mdxType="Button">Button A</Button>
    <Button fluid value="b" mdxType="Button">Button B</Button>
    <Button fluid value="c" mdxType="Button">Button C</Button>
  </Button.Group>
    </Fragment>
    <h2 {...{
      "id": "select-strategies"
    }}>{`Select Strategies`}</h2>
    <Fragment mdxType="Fragment">
  <Text mdxType="Text">Pre-defined strategies:</Text>
  <Text fontSize="small" mdxType="Text">simple</Text>
  <Button.Group strategy="simple" initialValue="b" onChange={value => console.log('selected', value)}>
    <Button value="a" mdxType="Button">Button A</Button>
    <Button value="b" mdxType="Button">Button B</Button>
    <Button value="c" mdxType="Button">Button C</Button>
  </Button.Group>
  <Text fontSize="small" mdxType="Text">multi:</Text>
  <Button.Group strategy="multi" initialValue={["a", "b"]} onChange={value => console.log('selected', value)}>
    <Button value="a" mdxType="Button">Button A</Button>
    <Button value="b" mdxType="Button">Button B</Button>
    <Button value="c" mdxType="Button">Button C</Button>
  </Button.Group>
  <Text mdxType="Text">Custom strategy</Text>
  <Button.Group initialValue="b" onChange={values => console.log('selected', values)} strategy={{
        isSelected: (selectedValue, value) => selectedValue === value,
        update: (selectedValue, value) => selectedValue === value ? undefined : value
      }}>
    <Button value="a" mdxType="Button">Button A</Button>
    <Button value="b" mdxType="Button">Button B</Button>
    <Button value="c" mdxType="Button">Button C</Button>
  </Button.Group>
    </Fragment>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      